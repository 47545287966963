<template>
  <div class="com-layout-CustomerService position-fixed bg-white text-center">
    <a-popover placement="leftTop">
      <template slot="content"> 13472858253 </template>
      <div class="customer-service">
        <cs-icon name="icon-dianhua-01" size="25" type="symbol" />
        <br />
        客服
      </div>
    </a-popover>
    <hr />
    <a-popover placement="left">
      <template slot="content">
        <cs-icon name="icon-guanzhugongzhonghao" size="130" type="symbol" />
        <br />
        <span class="font-size-12">扫一扫，关注微信公众号</span>
      </template>
      <div class="wechat-qrcode">
        <cs-icon name="icon-erweima" size="25" type="symbol" />
        <br />
        关注
      </div>
    </a-popover>
    <hr />

    <a-popover placement="leftBottom">
      <template slot="content">
        <a
          class="download-app"
          href="https://apple-file.weihaiqiaocai.com/download_page.html"
          target="_blank"
        >
          <!-- <cs-icon name="icon-xiazaiapp1" size="130" type="symbol" /> -->
          <img style="width: 130px;height: 130px;" :src="require('../../assets/downloadQr.png')" alt="">
        </a>
        <br />
        <span class="font-size-12">扫码下载微海APP</span>
      </template>
      <div class="wechat-qrcode">
        <cs-icon name="icon-xiazai" size="25" type="symbol" />
        <br />
        下载
        <br />
        APP
      </div>
    </a-popover>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320x) and (max-width: 1024px) {
  .com-layout-CustomerService {
    display:none;
  }
}
@media screen and (min-width: 1024px) {
  .com-layout-CustomerService {
    width: 55px;
    right: 0;
    bottom: 200px;
    box-shadow: 0px 0px 10px #eee;
    padding: 15px 10px;
    color: #374567;
    z-index: 10000;
    div {
      line-height: 18px;
    }
    hr {
      border: none;
      background: #dfe2eb;
      height: 1px;
      margin-top: 10px;
    }
 }
}

</style>